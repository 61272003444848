import { AfterContentInit, Attribute, Component, ElementRef, HostBinding, HostListener, Input } from "@angular/core";

@Component({
    selector: 'app-option',
    template: `<ng-content></ng-content>`,
    styleUrls: ['option.component.scss']
})
export class OptionComponent {
    @Input('value') value: any;
    @Input('text') _text?: string;
    @Input('label') _label?: string;
    @HostBinding('class.selected') selected = false;
    @HostBinding('class.disabled') disabled = false;
    
    // @HostBinding('attr.selected')
    get text() {
        return this._text || this._label ? <string>(this._label || this._text) : this.elt.nativeElement.innerText;
    }
    // set text(val: string) {
    //     this.elt.nativeElement.innerText = val;
    // }

    constructor(@Attribute('selected') public _selected: any, @Attribute('disabled') public _disabled: any, private elt:ElementRef<HTMLElement>) {
        this.selected = _selected != undefined;
        this.disabled = _disabled != undefined;
    }

    cb?: (option: OptionComponent) => void | any;
    @HostListener('click', ['$event']) onClick(ev: Event) {
        if(this.disabled) return;
        if(this.cb) this.cb(this);
    }

    registerCallback(cb: (option: OptionComponent) => void | any) {
        this.cb = cb;
    }
}