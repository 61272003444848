import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { AuthService } from "src/app/_services/auth.service";
import { SocialService } from "src/app/_services/social.service";

@Component({
    templateUrl: 'favorite-collections.component.html',
    styleUrls: ['favorite-collections.component.scss']
})
export class FavoriteCollectionsComponent implements OnInit {
    @Input() username!: string;
    favoriteCollections: string[] = [];

    constructor(
        private modal: ModalController,
        private socialService: SocialService,
        private router: Router,
        private auth: AuthService
    ) {
    }

    async ngOnInit() {
        await this.socialService.getUserFavorites(this.username).then(res => {
            this.favoriteCollections = res;
            console.log("Favorites:", res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    goToCollection(collection_name: string) {
        this.router.navigate(['/collection', collection_name]);
        this.modal.dismiss();
    }

    async unfavorite(collection_name: string, index: number) {
        // Needs to only be callable if the profile is that of the loggged in user.
        if(this.auth.user.username == this.username) {
            // Unfavorite the collection.
            await this.socialService.favoriteCol(this.username, collection_name, true).then(res => {
                this.favoriteCollections.splice(index, 1);
            }).catch(err => {
                console.log("Error Unfavoriting", err);
            });
        }
    }
}