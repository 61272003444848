import { AfterContentChecked, AfterContentInit, AfterViewInit, Attribute, Component, ContentChildren, EventEmitter, forwardRef, HostBinding, HostListener, Input, NgZone, OnInit, Output, QueryList } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
// import { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { OptionComponent } from "./option.component";

@Component({
    selector: 'app-select',
    templateUrl: 'select.component.html',
    styleUrls: ['select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ]
})
export class SelectComponent implements ControlValueAccessor, AfterContentChecked {
    @ContentChildren(OptionComponent) options?: QueryList<OptionComponent>;
    @Output('change') change = new EventEmitter<string>();
    @Input('name') name: string = 'select';
    // @Input('icon') icon: IconName = 'chevron-down';
    // @Input('icon-size') iconSize?: SizeProp;
    

    private _value: any;
    get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
        this.writeValue()
    }

    get selectedText(): string | undefined {
        return this.options?.filter(o => o.value == this._value).pop()?.text;
    }

    constructor(
        @Attribute('selected') public noIcon: any,
        @Attribute('rotates') public rotates: any
    ) {
        this.noIcon = noIcon != undefined;
        this.rotates = rotates != undefined;
    }

    @HostBinding('class.select-open') open: boolean = false;
    blurTimer?: NodeJS.Timeout;
    onFocus() {
        this.options?.forEach(o => {
            if(this.value && o.value == this.value && !o.selected) o.selected = true;
        })
        this.open = true;
        if(this.blurTimer) {
            clearTimeout(this.blurTimer);
            this.blurTimer = undefined;
        }
    }
    onBlur() {
        this.blurTimer = setTimeout(() => {
            this.open = false;
            clearTimeout(this.blurTimer);
            this.blurTimer = undefined;
        }, 100)
    }


    ngAfterContentChecked(): void {
        let hasValue = this.value != undefined;
        this.options?.forEach(o => {
            if(!hasValue) {
                if(o.selected) this._value = o.value;
            }
            o.registerCallback(this.onSelect.bind(this));
        })
    }

    onSelect(option: OptionComponent) {
        this.options?.filter(o => o != option).forEach(o => o.selected = false);
        option.selected = true;
        this.value = option.value;
    }

    onChange(_value: any) {};
    onTouch():void {}

    
    registerOnChange(fn: (_v: any) => {}) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => {}) {
        this.onTouch = fn;
    }


    writeValue(_value?: any) {
        if(_value) {
            this._value = _value;
            this.options?.forEach(o => {
                if(o.value == _value) o.selected = true;
                else o.selected = false;
            })
        } else {
            this.onChange(this._value);
            this.change.emit(this._value);
        }
    }
    // // change events from the textarea
    // private onChange() {
    //     // update the form
    //     this.onChangeCallback(this._value);
    //     this.change.emit(this._value);
    // }
}