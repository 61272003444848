import { Attribute, Component, Input } from "@angular/core";

@Component({
    selector: 'app-collage-card',
    templateUrl: './collage-card.component.html',
    styleUrls: ['./collage-card.component.scss']
})
export class CollageCardComponent {
    @Input() collection?: string;
    // Will replace dummyCollection with a get request that queries for 3 random NFTs from the provided input 'collection' ( col Name )

    dummyCollections = {
        'california skies': {
            img1: 'https://media.istockphoto.com/photos/san-francisco-bay-golden-gate-bridge-sunrise-marin-headlands-picture-id171148358?k=20&m=171148358&s=612x612&w=0&h=ZdT84rhy-7t_XhMHOPzj5ItJTPZa8DaATu8fTkXUqxM=',
            img2: 'https://media.istockphoto.com/photos/beautiful-pastel-cloudy-sunset-with-blue-sky-in-california-picture-id843414444?k=20&m=843414444&s=612x612&w=0&h=IoKK3TEbV5tKXaEEyjAWplw2lVRmM_Bcyb-gyTUTpTw=',
            img3: 'https://www.tripsavvy.com/thmb/mjyJOArAkbMrcYsPK31CLf6HRWk=/900x0/filters:no_upscale():max_bytes(150000):strip_icc():gifv():format(webp)/GettyImages-951760280-5b15669e8e1b6e003669db43.jpg'
        },
        'to the moon': {
            img1: "https://images.pexels.com/videos/5921369/earth-earth-rotate-space-5921369.jpeg?auto=compress&cs=tinysrgb&h=566.525&fit=crop&w=633.175&dpr=1",
            img2: 'https://images.unsplash.com/photo-1529788295308-1eace6f67388?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80',
            img3: 'https://i.guim.co.uk/img/media/3010c3614221f278b7f867da0f9c37a709ed2766/0_1799_6000_3601/master/6000.jpg?width=1200&quality=85&auto=format&fit=max&s=ded899ad678387018b4b9b3b775ef3ae'
        },
        'abstracts': {
            img1: 'https://cdn.wallpapersafari.com/43/79/wW4o9T.jpg',
            img2: 'https://img.fixthephoto.com/blog/images/gallery/news_preview_mob_image__preview_1593.jpg',
            img3: 'https://wallpaperaccess.com/full/4292476.jpg'
        }
    }

    constructor() {

    };
}