import { environment } from "src/environments/environment"

export const MANDOX_ACCOUNT = 'mandox';
export const MANDOX_MARKET_FEE = 0.015;
// export const MANDOX_API = 'http://localhost:3000'
// export const MANDOX_API = 'http://192.168.50.193:3000'
export const MANDOX_API = 'https://api.mandoxcreate.io'
export const HYPERION : string = "https://hyperwire.airwire.io"
export const CHAIN : string = "https://swamprod.airwire.io"
export const WIRE_API : string = 'https://api.airwire.io'

export const IPFS_GATEWAY : string = "https://ipfs.airwire.io/ipfs/"
export const IPFS_SUFFIX : string = "/image.png"

export const AES_KEY : string = "mandox-secret" // CHANGE THIS PER DAPP
export const BLOKS : string = "https://local.bloks.io/account/{{ACCOUNT}}?nodeUrl=https%3A%2F%2Fwire.siliconswamp.info&coreSymbol=WIRE&systemDomain=eosio"
export const BLOKS_TRX : string = "https://local.bloks.io/transaction/{{TRX_ID}}?nodeUrl=https%3A%2F%2Fwire.siliconswamp.info&coreSymbol=WIRE&systemDomain=eosio"

export const EXPLORER_TRANSACTION : string = "https://explorer.airwire.io/transaction/"

export const DEFAULT_PFP : string = "/assets/pfp.png"

export const COLLECTION_SCHEMA = [{ name: "external", type: "string" },{ name: "icon", type: "string" }]

export const DEFAULT_SETTINGS : any = {
    dark: false,
    bio: false,
    pin: false
}
export const CURRENCIES : any = [
    { 
        symbol: "USD",
        name: "US Dollar" 
    },
    { 
        symbol: "EUR",
        name: "Euro" 
    },
    {
        symbol: "GBP",
        name: "British Pound"
    }
]
export const CURRENCY_SYMBOLS : any = {
    usd: "$",
    eur: "€",
    gbp: "£"
}
export const MARKET_ACTIONS : string[] = [ 
    "createcol", 
    "addcolauth", 
    "remcolauth", 
    "addnotifyacc", 
    "remnotifyacc", 
    "createschema" ,
    // "extendschema",
    "addedition",
    "mintasset",
    "burnasset",
    "transfer",
    "createoffer",
    "acceptoffer",
    "canceloffer",
    "declineoffer",
    "announcesale",
    "cancelsale",
    "purchasesale",
    "transfer"
]