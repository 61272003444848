import { ElementRef, EventEmitter, Input, NgZone } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { ContractService } from 'src/app/_services/contract.service';
import { EthAsset } from 'src/app/_services/nft.service';
import { IPFS_GATEWAY, IPFS_SUFFIX } from '../../_constants/constants'
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/_services/auth.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
  selector: 'app-eth-nft-card',
  templateUrl: './eth-nft-card.component.html',
  styleUrls: ['./eth-nft-card.component.scss'],
})
export class EthNftCardComponent implements OnInit {
    @Input() ethAsset? : EthAsset
    @Input() price? : number
    @Input() style? : string
    @Input() noOptions? : boolean
    @Input() loading? : boolean
    @Input() mystery? : boolean | string
    @Input() scrollElement?: HTMLElement;
    @Input() scrollEvent?: EventEmitter<string>
    @Input() testVis?: boolean;
    @Input() noCollection?: boolean;
    @Input() numRows: number = 4;
    @Input() reveal?: 'hidden' | 'show';
    @Input() card_image: string | undefined;
    @Input() noCountdown?: boolean;
    @Input() fullImg?: boolean;

    loadedHeight?: number

    imgLoading = true
    maxSupply : number = 0

    isVerified = false

    live : any
    
    boundingLimit: number = 5;
    validBoundingTags: string[] = ['ion-content']

    has_animation: boolean = false;

    IPFS_SUFFIX = IPFS_SUFFIX
    IPFS_GATEWAY = IPFS_GATEWAY

    // get assetType(): 'image' | 'video' | undefined {
    //     if(this.asset) {
    //         return undefined;
    //     } else if(this.ethAsset) {
    //         if(this.ethAsset.metadata) {
    //             if(this.ethAsset.metadata.image && typeof this.ethAsset.metadata.image == 'string') {
    //                 let ext = this.ethAsset.metadata.image.split('.').pop();
    //                 if(['png', 'jpeg', 'jpg'].includes(ext)) return 'image';
    //                 if(['mp4', 'webm'].includes(ext)) return 'video';
    //             }
    //         }
    //     }
    //     return undefined;
    // }

    loaded = false;
    ended = false

    isApiAsset = false

    get isVideo(){
        if (this.ethAsset && this.ethAsset.metadata){
            if (this.ethAsset.metadata.image && this.ethAsset.metadata.image.endsWith('.mp4'))
                return true
            else return false
        } 
        
        else return false
    }


    get isVisible(): boolean {
        let vis = true;
        
        if(this.self.nativeElement && this.scrollElement) {
            let el = this.self.nativeElement.getBoundingClientRect();
            let scroll = this.scrollElement.getBoundingClientRect();

            let topVis = el.top <= scroll.bottom
            let bottomVis = el.bottom >= scroll.top;
            let leftVis = el.left <= scroll.right;
            let rightVis = el.right >= scroll.left;
            let full = topVis && bottomVis && leftVis && rightVis;
            if(this.testVis) console.log(topVis, rightVis, bottomVis, leftVis, 'Full:', full);
            
            vis = full;
        }
        return vis;
    }

    get revealable() : boolean {
        return this.reveal != undefined;
    }

    get now(){
        return new Date().getTime()
    }

    constructor(
        public system : SystemService,
        private router : Router,
        private popover : PopoverController,
        private contract : ContractService,
        private sanitizer : DomSanitizer,
        private auth : AuthService,
        private self: ElementRef<HTMLElement>,
        public zone: NgZone
        ) {
        }

    ngOnInit() {
        if(this.scrollEvent) this.scrollEvent.subscribe((event) => {
            this.zone.run(() => this.isVisible);
        })

        if(this.ethAsset) {
            if(typeof this.ethAsset.metadata == 'string') {
                this.ethAsset.metadata = JSON.parse(this.ethAsset.metadata);
            }
            if(this.ethAsset.metadata && this.ethAsset.metadata?.image) {
                if(this.ethAsset.metadata.image.includes('ipfs://ipfs/')) {
                    let trimmed = String(this.ethAsset.metadata.image).substr(12);
                    this.ethAsset.metadata.image = `https://ipfs.airwire.io/ipfs/${trimmed.replace('/ipfs/', '')}`;
                }
    
                if(this.ethAsset.metadata.image.includes('ipfs://')) {
                    let trimmed = String(this.ethAsset.metadata.image).substr(7);
                    this.ethAsset.metadata.image = `https://ipfs.airwire.io/ipfs/${trimmed.replace('/ipfs/', '')}`;
                }

                this.ethAsset.metadata.image.replace('/ipfs/ipfs/', '/ipfs/')
                this.ethAsset.metadata.image.replace('ipfs.io', 'ipfs.airwire.io')
            }
        }

        if (!this.numRows) this.numRows = 4
        // if (!this.loading && this.ethAsset){
        //     console.log("ETH ASSET", this.ethAsset);
        // }
    }
    

    onLoad() {
        this.imgLoading = false;
    }

    async openOptions(e : Event){
        // e.stopPropagation();

        // const popover = await this.popover.create({
        //     component: NftOptionsComponent,
        //     cssClass: 'options-popover',
        //     event: e,
        //     translucent: true,
        //     componentProps: { asset : this.apiAsset ? this.apiAsset : this.asset }
        // });
        // await popover.present();
    }

    clean(str : string){

    }

    apiPrice(price : string){

    }
}
