import { HttpClient } from "@angular/common/http";
import { Component, Input, Output, ViewChild, ElementRef, OnInit } from "@angular/core";
import { Message, MessageEvent } from "src/app/types/types";
import { MANDOX_API } from "src/app/_constants/constants";
import { AlertController } from "src/app/_services/alert.service";
import { AuthService, User } from "src/app/_services/auth.service";
import { ContractService } from "src/app/_services/contract.service";
import { MessagingService } from "src/app/_services/messaging.service";
import { SystemService } from "src/app/_services/system.service";

// 15 MB
const MAX_FILE_SIZE = 15000000;

@Component({
    selector: 'app-message-input',
    templateUrl: './message-input.component.html',
    styleUrls: ['./message-input.component.scss']
})
export class MessageInputComponent implements OnInit {

    private to?: string;
    @Input('to') set _to(val: undefined | string) {
        // console.log('TO SET');
        this.to = val;
        this.ngOnInit();
    }
    @ViewChild('fileinput') fileInput?: ElementRef<HTMLInputElement>;
    @ViewChild('preview') preview?: ElementRef<HTMLImageElement>;

    // For file uploads
    formData?: FormData;
    uploadFile?: File;
    get showPreview(): boolean {
        return this.uploadFile !== undefined;
    }
    message: string = "";
    emojiToggle: boolean = false;
    convo_id?: string;
    validUsername?: boolean;

    constructor(
        private messageService: MessagingService,
        private auth: AuthService,
        private contract: ContractService,
        private http: HttpClient,
        private alert: AlertController
    ) {
    }
    
    async ngOnInit() {
        if(this.auth.user && this.to) {
            this.convo_id = this.messageService.cantor(this.auth.user.username, this.to);
        }
    }

    
    /**
     * TODO: Write out function description once fully modified.
     */
    async sendMsg() {
        if(!this.messageService.allConversations[this.convo_id!]) {
            if(this.to?.length) {
                // Make sure the name is even valid before making contract calls.
                if(this.contract.validWireName(this.to)) {
                    // Check Mandox users contract for exact matches of entered username.
                    await this.contract.getRows<User>({   
                        scope: 'mandox.users',
                        contract: 'mandox.users',
                        table: 'users',
                        lower_bound: this.to,
                        upper_bound: this.to,
                        limit: 1
                    }).then(async (data) => {
                        console.log("User Results: ", data.rows);
                        // Making sure there was a result AND that it matches the typed input
                        if (data.rows.length && data.rows[0].username == this.to) this.validUsername = true;
                        else this.validUsername = false; // No user found that matches input.
                    }, (err:any) => {
                        console.log("Error validating intended user exists: ", err);
                    })
                } else {
                    // Gives us a red outline if the name doesn't meat Mandox (Wire) username requirements.
                    this.validUsername = false;
                }
            } else {
                // ON Empty string set to undefined so we don't get a red outline by default.
                this.validUsername = undefined;
            }
        } else {
            this.validUsername = true;
        }

        // Removes trailing and leading white spaces
        let tmp_msg = this.message.replace(/^\s+|\s+$/g, '')
        
        if(this.to && this.validUsername && (tmp_msg.length || this.uploadFile) && this.convo_id) {
            let newConvo = false;
            console.log("TMP-MSG:", tmp_msg);
            
            let tmp: MessageEvent = {
                conversation_id: this.convo_id,
                to: this.to,
                from: this.auth.user.username,
                msg: tmp_msg,
                filename: this.uploadFile ? new Date().getTime().toString() : undefined // Going to set the name of files using Date().getTime()
            }

            console.log("TMP-MSG OBJ:", tmp);

            // IF a file was staged. 
            if(tmp.filename) {
                // Create formData object and upload.
                this.formData = new FormData();
                // Adds the file to the formData: 'the key that will be used on the other end to access this element', the file, 'what the file name will be'
                this.formData.append('file', this.uploadFile!, tmp.filename);

                // console.log("Form Data: ", this.formData);
                
                // Post the image file off to the API.
                await this.http.post(MANDOX_API + '/msg-img-upload', this.formData).toPromise().catch(async (err) => {
                    // Failed to upload image
                    console.log("Failure to upload image:", err.msg);
                    // ! Good way to error handle this?
                    const alert = await this.alert.create({
                        header: 'Error Sending Message',
                        message: `There was an error sending your message due to the image being sent. Try again.`
                    })

                    alert.present();

                    this.removeImg();
                    return;
                })
            }

            // If the conversation doesn't exist, create it.
            if(!this.messageService.allConversations[this.convo_id]) {
                this.messageService.allConversations[this.convo_id] = { 
                    id: this.convo_id, // The conversation id.
                    to: this.to,
                    unread: 0, // Count of unread messages
                    timestamp: new Date().toISOString().slice(0, 19).replace('T', ' '), //Timestamp of most recent message
                    messages: []
                }
    
                this.messageService.getUserProfiles();

                newConvo = true;
            }

            // Final Calls / Clean up.

            // Send the message through via the socket.
            this.messageService.sendMessage(tmp);
            // Reset the msg input field after sending.
            this.message = "";
            // Reset image staging variables. If a filename is present indicating a file was uploaded.
            if(tmp.filename) this.removeImg();

            // Handles swapping to the 'active conversation' view, from 'new conversation' view.
            setTimeout(() => {
                // Need this timeout so the message just sent has time to arrive before opening the conversation.
                this.messageService.emit('new-convo-started', this.convo_id);
            }, 250);
        } else {
            // TODO: Put user facing error handling?
            console.log('CANT SEND??');
        }
    }

    /**
     * Un-stage an image and resets all staging variables to initial state.
     */
    removeImg() {
        this.preview!.nativeElement.src = '';
        this.uploadFile = undefined;
    }

    /**
     * Adds an emoji from the 'emojimart' package into our 'message' variable appending it to the end.
     * 
     * @param event the event caused by adding an emoji, contains all necessary info.
     */
    addEmoji(event: any) {
        // console.log("Emoji Event:", event);
        this.message += event.emoji.native;
    }

    /**
     * Shows a preview for an image staged to be sent with a user's message.
     * 
     * @param event the event caused by adding an image to the input field.
     */
    async imgPreview(event: InputEvent) {
        console.log("Preview Event: ", event);
        
        if(!this.preview || !event.target) return;
        let target = <HTMLInputElement>event.target;
        if(!target.files || target.files[0].size > MAX_FILE_SIZE) {
            const alert = await this.alert.create({
                header: 'Image to large',
                message: `The image you are trying to send exceeds the 15mb file size limit.`
            })

            alert.present();
        }
        else {
            let file = this.uploadFile = target.files[0];
            
            console.log("Img Preview File:", file);
            
            this.preview.nativeElement.src = URL.createObjectURL(file);
            this.preview.nativeElement.onload = () => {
                URL.revokeObjectURL(this.preview!.nativeElement.src) // free memory
            }
        }
        
    }

    /**
     * Handles the case where a user 'pastes' an image into the message input field.
     * 
     * @param event Clipboard event.
     */
    async onPaste(event: ClipboardEvent) {
        if(event.clipboardData?.files.length) {
            if(event.clipboardData.files[0].size <= MAX_FILE_SIZE) {
                const alert = await this.alert.create({
                    header: 'Image to large',
                    message: `The image you are trying to send exceeds the 15mb file size limit.`
                })
    
                alert.present();
            } else {
                // console.log("Paste Event:", event.clipboardData); 
                let file = this.uploadFile = event.clipboardData.files[0];
                this.fileInput!.nativeElement.files = event.clipboardData.files;
                this.fileInput!.nativeElement.dispatchEvent(new Event('change'));
            }
            // console.log('File', file);
        }
    }

    textFocusUpdate(focus: boolean) {
        this.emojiToggle = false;
        if(focus) {
            window.addEventListener("keypress", this.onKeyPress)
        } else {
            window.removeEventListener("keypress", this.onKeyPress)
        }
    }

    onKeyPress = this.keyPress.bind(this)
    keyPress (event: KeyboardEvent) {
        // console.log("key press event:", event);
        if(event.key == "Enter" && !event.shiftKey) {
            // event.preventDefault();
            setTimeout(() => {
                this.sendMsg();
            }, 50)
        }
    }
}

