import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './_components/components.module';
import { AuthService } from './_services/auth.service';
import { SystemService } from './_services/system.service';
import { CryptoService } from './_services/crypto.service';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { IProviderControllerOptions, IProviderOptions } from '@mindsorg/web3modal-angular/lib/web3modal-ts/src';
import { SwiperModule } from 'swiper/angular';
import { Web3ModalService } from '@mindsorg/web3modal-angular';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalsModule } from './_modals/modals.module';
import { ContractService } from './_services/contract.service';
import { ConnectService } from './_services/connect.service';
import { LoadingController } from './_services/load.service';
import { AlertController } from './_services/alert.service';
import { NftService } from './_services/nft.service';
import { DataService } from './_services/data.service';
import { MessagingService } from './_services/messaging.service';
import { appTextareaAutosizeDirective } from './_directives/textarea-auto-resize/textarea-auto-resize.directive';
import { SocialService } from './_services/social.service';


const providerOptions: IProviderOptions = {
	injected: {
		display: {
			logo: "../assets/metamask.svg",
			name: "Metamask",
			description: "Connect with the provider in your Browser"
		},
		package: null
	},
	walletconnect: {
		display: {
			logo: "../assets/walletconnect-circle.svg",
			name: "Wallet Connect",
			description: "Scan qrcode with your mobile wallet"
		},
		package: WalletConnectProvider,
		options: {
			infuraId: "19e7be8536f84defad83ca89cdef4fc8", // required
		}
	}
};

@NgModule({
	declarations: [ 
		AppComponent
	],
	bootstrap: [AppComponent],
    imports: [
		BrowserModule, 
		HttpClientModule,
		IonicModule.forRoot(), 
		AppRoutingModule,
		ComponentsModule,
		SwiperModule,
 		BrowserAnimationsModule,
		ModalsModule
	],
    providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: Web3ModalService,
			useFactory: () => {
			  return new Web3ModalService({
				network: "mainnet",
				cacheProvider: false,
				providerOptions
			  } as IProviderControllerOptions);
			},
		},
		HttpClient,
		Storage,
		Clipboard,
		AuthService,
		SystemService,
		CryptoService,
		ContractService,
		ConnectService,
		DataService,
		MessagingService,
		LoadingController,
		AlertController,
		NftService,
		SocialService
	],
})
export class AppModule {}
