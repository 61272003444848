import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MANDOX_API } from "../_constants/constants";

@Injectable()
export class SocialService {

    // TODO: 
    // 1. Build out the like / unlike functionality - ***DONE***
    // 2. Build out the functionality needed to get the number of likes the asset has and whether or not the user that is viewing it has liked it.
    // 3. Build out the favorite / unfavorite functionality
    // 4. Build out the function to get the number of favorites a collection has
    // 5. Build out the function to get a list of collections that a user has favorited.
    // 6. Build out the function to get a list of assets that a user has liked. * Do we need this? *

    constructor(
        private http: HttpClient
    ) {

    }

    /**
     * Gets the number of likes for an NFT and if a user is logged in whether or not they have liked it.
     * @param assetId the asset Id of the NFT
     * @param username the username of the user IF a user is logged in
     * @returns the number of likes, and whether or not 'this.auth.user' the user has liked it.
     */
    getNftLikes(asset_id: string, username?: string): Promise<{count: number, user_liked: boolean}> {
        let params = new HttpParams().set('asset_id', asset_id);

        if (username) params = params.set('username', username);

        return this.http.get(MANDOX_API + '/numNftLikes', { params }).toPromise().then((res: any) => { 
            return res; 
        }).catch((err: any) => { 
            return err; 
        });
    }

    /**
     * Gets the number of favorites for a collection and if a user is logged in whether or not they have favorited it.
     * @param colName the name of the collection
     * @returns the number of favorites
     */ 
    getNumFavorites(collection_name: string, username?: string): Promise<{count: number, user_favorited: boolean}> {
        let params = new HttpParams().set('collection_name', collection_name);

        if (username) params = params.set('username', username);

        return this.http.get(MANDOX_API + '/numColFavorites', { params }).toPromise().then((res: any) => { 
            return res; 
        }).catch((err: any) => { 
            return err; 
        });
    }

    /**
     * Gets a list of assetIds of NFTs that a user has liked
     * @param username the username of the user
     * @returns a list of assetIds
     */
    getUserLikes(username: string) {

    }

    /**
     * Gets a list of collection names that a user has favorited
     * @param username the username of the user
     * @returns a list of collection names
     */
    getUserFavorites(username: string): Promise<string[]> {
        let params = new HttpParams().set('username', username);

        return this.http.get(MANDOX_API + '/getUserFavorites', { params }).toPromise().then((res: any) => { 
            return res; 
        }).catch((err: any) => { 
            return err; 
        });
    }

    /**
     * Likes / Unlikes an NFT
     * @param username the username of the user
     * @param assetId the asset Id of the NFT
     * @param unlike whether or not the user is unliking the NFT
     * @returns success or failure.
     */
    async likeNft(username: string, asset_id: string, unlike: boolean): Promise<{success: boolean, msg: string}> {
        return new Promise(async (resolve, reject) => {
            let data = {
                username,
                asset_id,
                unlike
            }

            await this.http.post(MANDOX_API + '/likeNft', data).toPromise().then((res: any) => { 
                resolve(res); 
            }).catch((err: any) => { 
                reject(err); 
            });
        })
    }

    /**
     * Favorites / Unfavorites a Collection
     * @param username the username of the user
     * @param colName the name of the collection
     * @param unfavorite whether or not the user is unfavoriting the collection
     * @returns success or failure.
     */
    async favoriteCol(username: string, collection_name: string, unfavorite: boolean): Promise<{success: boolean, msg: string}> {
        let data = {
            username,
            collection_name,
            unfavorite
        }

        return await this.http.post(MANDOX_API + '/favoriteCol', data).toPromise().then((res: any) => {
            return res;
        }).catch((err: any) => {
            return err;
        })
    }

    /**
     * Comment on a collection
     */
    comment() {

    }
}