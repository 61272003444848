import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; 
// import { MatProgressBarModule } from '@angular/material/progress-bar'; 
import { ImagePicker } from '@ionic-native/image-picker/ngx';

@NgModule({
    declarations: [
        
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatDialogModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatSelectModule,
        MatDividerModule,
        MatExpansionModule,
        MatSliderModule,
        MatCheckboxModule,
        // NgxSkeletonLoaderModule,
        MatIconModule,
        MatSnackBarModule,
        MatButtonModule,
        MatTabsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        // MatProgressBarModule,
    ],
    exports: [
        MatSlideToggleModule,
        MatRadioModule,
        MatDialogModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatSelectModule,
        MatDividerModule,
        MatExpansionModule,
        MatSliderModule,
        MatCheckboxModule,
        // NgxSkeletonLoaderModule,
        MatIconModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        // MatProgressBarModule
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        ImagePicker
    ]
})
export class MaterialModule {}