import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { io } from "socket.io-client";
import { EventEmitter } from "stream";
import { Message, MessageEvent } from "../types/types";
import { MANDOX_API } from "../_constants/constants";
import { AuthService, User } from "./auth.service";
import { ContractService } from "./contract.service";
// const nameToUint64 = require('eosjs-account-name/src/nameToUint64.js');
const {nameToUint64 } = require('eosjs-account-name');

@Injectable()
export class MessagingService extends EventEmitter implements OnInit {
    // Manages emitted events.
    // private events : any = {};
    activeConversationId?: string;
    private socket: any;

    private listenersReady = false;

    allConversations: Conversations = {};
    pfpLoadingFlags: boolean[] = [];

    validUserName?: boolean;

    loggedInUsername?: string;

    // Will hold a username when someone is starting a msg via: Profile, NFT, or Collection pages.
    routeAndMsgUser?: string;

    get conversationsSortedByTimestamp(): Convo[] {
        if(this.allConversations) {
            
            return Object.values(this.allConversations).sort((a, b) => {
                let aTime: Date = new Date(a.timestamp);
                let bTime: Date = new Date(b.timestamp);
    
                return aTime > bTime ? 1 : bTime > aTime ? -1 : 0;
            })
        }

        return [];
    }

    constructor(
        private auth: AuthService,
        private contract: ContractService,
        private route: Router
    ) {
        super();
        if(this.auth.user) {
            this.ngOnInit();
        } else {
            this.auth.on('login').subscribe(() => {
                this.ngOnInit();
            }) 
        }
    }
    
    async ngOnInit() {
        // If user is logged in
        if(this.auth.user) {
            // Verify username and key are valid pair.
            await this.auth.verifyUsernameKey().then(() => {
                // console.log("Validated username successfully.");
                this.loggedInUsername = this.auth.user.username;
                this.validUserName = true;
                // Only setting up socket listeners if the user passes our validation checks. Further checks in place on the API.
                if (this.contract.ready) this.setupListeners(); // If the contract is ready set up listeners
                else this.contract.on('contractReady').subscribe(() => { this.setupListeners(); }) // Else wait till the contract is ready then set up listeners.
            }).catch((err) => {
                console.log("error verifying username:", err);
                
                this.validUserName = false;
                this.loggedInUsername = undefined;
                this.auth.logoutHard();
            })
        }
    }

    /**
     * Sets up all socket listeners required for messaging.
     */
    setupListeners() { 
        if(!this.validUserName || !this.loggedInUsername) return;
        if(this.listenersReady) return;

        // Initialize the connection, this kicks of the socket 'emits'
        this.socket = io(MANDOX_API); // ! Note: When developing or testing locally this will need to be switched accordingly.
        
        // Sets up socket connection
        this.socket.on('get-auth', () => {
            if(this.auth.user) {
                // Will send username and a signature to our API to verify the user.
                let sig = this.auth.generateSignature().catch((err) => {
                    console.log("Error Generating Signature in get-auth");
                });

                // console.log("Sig:", sig);
                
                // Send username and signature to auth the user on the API side.
                this.socket.emit('socket-auth', {username: this.loggedInUsername, signature: sig});
            }
        });
        // Sets up socket connection
        this.auth.on('login').subscribe((user) => {
            // console.log("User:", user);
            
            if(this.socket.connected && user && typeof user == 'object') {
                this.socket.emit('socket-auth', {username: user.username});
            } 
        })

        // When a user logs out on the front end sends and emit to close the socket connection.
        this.auth.on('logout').subscribe(() => {
            this.socket.emit('logout');
        })

        // When a new message is received
        this.socket.on('new-message', (msg: Message) => {
            // console.log('NEW MESSAGE', msg);

            if(!this.allConversations[msg.conversation_id]) {
                this.allConversations[msg.conversation_id] = { 
                    id: msg.conversation_id, // The conversation id.
                    to: this.loggedInUsername!,
                    unread: 0, // Count of unread messages
                    timestamp: new Date(msg.timestamp), //Timestamp of most recent message
                    messages: []
                }

                this.getUserProfiles();
            }

            let convo = this.allConversations[msg.conversation_id];
            // Push new message in to array of messages
            convo.messages?.push(msg);
            // Increment unread msg count if its not your message.
            if(msg.from != this.loggedInUsername && this.activeConversationId != msg.conversation_id)convo.unread++;
            this.emit('new-message', msg.conversation_id);
        })

        // Grabs all existing conversations, emitted from API after socket connection has been established.
        this.socket.on('get-all-convos', (conversations: any) => {
            if(conversations.length) {
                this.pfpLoadingFlags = new Array(conversations.length).fill(true);
                
                // loop through conversations and add to allConversations object.
                for(let convo of conversations) {
                    this.allConversations[convo.id] = {
                        id: convo.id,
                        to: convo.userone == this.loggedInUsername ? convo.usertwo : convo.userone,
                        unread: convo.unread,
                        timestamp: convo.timestamp,
                        messages: []
                    }
                }
            }

            // console.log("All Convos:", this.allConversations);
            this.getUserProfiles();
        })

        // After the socket receives 'get-messages' messages are retrieved from the database, when they are ready the socket will emit 'messages-ready'
        this.socket.on('messages-ready', (messages: any) => {
            // console.log("Messages Received:", messages);
            // Make sure there were actually messages sent back.
            if(messages.length) {
                // TODO: Loop through messages and if any have an image handle that.


                // Assign messages to messages array.
                this.allConversations[messages[0].conversation_id].messages = messages;
                // Emit that messages are ready to be displayed
                this.emit('messages-ready', messages[0].conversation_id)

                // Since this function getting called means a conversation is being viewed by the user. Set unread to 0.
                this.allConversations[messages[0].conversation_id].unread = 0;
            }
        })

        // When get more messages is called via pagination this is the event to listen for, for the additional messages. Appends new messages to the front of the respective messages array.
        this.socket.on('additional-messages-ready', (messages: any) => {
            // console.log("Additional Messages: ", messages);
            if(messages.length) {
                // Then append the new messages to the FRONT of the array of messages related to this conversation.
                this.allConversations[messages[0].conversation_id].messages?.unshift(...messages);
                this.emit('additional-messages-added');
            }
        })

        this.listenersReady = true;
    }

    sendMessage(message: MessageEvent) {
        if(this.validUserName) this.socket.emit('message', message);
    }

    /**
     * Called when user is trying to message a user via Profile page, NFT page or Collection page. Routes to message page and opens the relevant conversation if it already exists, otherwise takes you to 'new message' view with username auto populated. NOTE: 'routeAndMsgUser' once routing is handled should be set back to undefined.
     * 
     * @param username Mandox user name.
     */
    routeAndMessage(username: string) {
        // console.log("Route & Msg:", username);
        // Just to make sure the name isn't altered.
        if(this.contract.validWireName(username)) {
            this.routeAndMsgUser = username;
            this.route.navigate(['/message']);
            this.emit('route-and-convo'); // Message page is listening for this event. If message page hasn't been initialized yet, the constructor has a case to handle it. Otherwise listeners have been set up and are ready for this event.
        }
    }

    /**
     * Emits a 'get-messages' event OR 'get-more-messages' over the socket, providing conversation_id and the username of the other person in the conversation. The response will be received by our event listener 'messages-ready' OR 'additional-messages-ready' respectively, which is subscribed to in the constructor. 'get-messages' is called when a user opens a conversation, 'get-more-messages' gets called when a user paginates to the top of the currently loaded message history.
     * 
     * @param convo_id Conversation Id messages are being requested for.
     * @param to The username of the user you are trying to view messages with.
     * @param messageOffSet the number of messages currently in the messages array of the respective conversation.
     */
    getMessages(conversation_id: string, to: string, messageOffSet?: number) {
        if(!messageOffSet) this.socket.emit('get-messages', {conversation_id, to});
        else this.socket.emit('get-more-messages', {conversation_id, to, messageOffSet})
    }

    /**
     * Given two Wire account names, generates a unique identifier for the pair.
     * 
     * @param a Username of one person in the conversation.
     * @param b Username of the other person in the conversation.
     * @returns A unique string to be used as an identifier for the two parties messaging.
     */
    cantor(a: string, b: string): string {
        let aNum = +nameToUint64(a);
        let bNum = +nameToUint64(b);
        // console.log("A:", aNum);
        // console.log("B:", bNum);
        
        let numA = aNum;
        let numB = bNum; 

        if(aNum > bNum) {
            numB = aNum;
            numA = bNum;
        }
        let cont = (numA + numB) * (numA + numB + 1) / 2 + numA;
        let bigi = BigInt(cont);
        return bigi.toString(36)
    }

    /**
     * Grabs user profile data for each open conversation the user has.
     */
    async getUserProfiles() {
        // loop through each user
        for(let user of Object.values(this.allConversations)) {
            // for each user get table rows their profile data.
            if(user.userProfile) continue;
            await this.contract.getUser(user.to).then((res) => {
                // console.log("User: ", res);
                user.userProfile = res;
            }).catch((err) => {
                console.log("Error retrieving user from contract:", err);
            })
        }
    }
}

export interface Conversations {
    [key: string]: Convo
}

export interface Convo {
    id: string; // The conversation id.
    to: string;
    unread: number; // Count of unread messages
    timestamp: string | Date; //Timestamp of most recent message
    messages?: Message[];
    userProfile?: User;
}