import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LoadingComponent } from "../_modals/loader/loader.component";

@Injectable()
export class LoadingController {
    private loaders: Loader[] = []

    constructor(private dialog: MatDialog) {

    }

    async create(options: LoadingOptions) {
        let l = new Loader(this, this.dialog, options);
        this.loaders.push(l);
        return l;
    }

    dismiss() {
        for(let i = 0; i < this.loaders.length; i++) {
            let l = this.loaders[i];
            l.dismiss();
        }
    }

    destroy(id: string) {
        for(let i = 0; i < this.loaders.length; i++) {
            let l = this.loaders[i];
            if(l.id == id) {
                this.loaders.splice(i, 1);
                break;
            }
        }
    }
}

class Loader {
    ref?: MatDialogRef<LoadingComponent>;
    id: string;

    constructor(private parent: LoadingController, private dialog: MatDialog, private options: LoadingOptions) {
        this.id = Math.round(Math.random() * new Date().getTime()).toString(16);
    }

    present() {
        this.ref = this.dialog.open<LoadingComponent, any, LoadingOptions>(LoadingComponent, {
            data: this.options,
            disableClose: this.options.backdropDismiss,
            panelClass: this.options.cssClass ?  this.options.cssClass : 'loading-overlay'
        })
    }

    dismiss() {
        if(this.ref) {
            this.ref.close();
            this.parent.destroy(this.id);
        }
    }
}

export interface LoadingOptions {
    spinner: 'crescent', 
    message: string, 
    cssClass?: string, 
    backdropDismiss?: boolean
}