import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: 'app-spinner',
    template: ``,
    styleUrls: ['spinner.scss']
})
export class SpinnerComponent {
    @Input('size') size: 'small' | 'medium' | 'large' = 'small';
    @HostBinding('class.small') private get isSmall(): boolean {
        return this.size == 'small';
    }
    @HostBinding('class.medium') private get isMedium(): boolean {
        return this.size == 'medium';
    }
    @HostBinding('class.large') private get isLarge(): boolean {
        return this.size == 'large';
    }
}