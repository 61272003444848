import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoadingOptions } from "src/app/_services/load.service";

@Component({
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss']
})
export class LoadingComponent {

    get spinner(): string | undefined {
        return this.data.spinner
    }

    get message(): string {
        return this.data.message
    }

    constructor(
        public dialogRef: MatDialogRef<LoadingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LoadingOptions
    ) {

    }
}