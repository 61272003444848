const regex = /([0-9]+)(([.]{1})([0-9]*)?)?(\ {1}[a-zA-Z]{3,5})/gs;

export class Asset {
    value: number = 0;
    precision: number = 0;
    symbol: string = 'TOK';

    constructor(value: string)
    constructor(value: string | number, precision: number, symbol: string)
    constructor(value: string | number, precision?: number, symbol?: string) {
        if(typeof value == 'string' && !precision && !symbol) {
            if(new RegExp(regex).test(value)) {
                let exec = new RegExp(regex).exec(value)!;
                let [, int, dec, , prec, sym] = exec;
                this.value = parseFloat(int + dec);
                this.precision = prec ? prec.length : 0;
                this.symbol = sym.trim().toUpperCase();
            } else {
                throw new Error('Invalid constructor arguments');
            }
        } else if(precision != undefined && symbol != undefined) {
            if(typeof value == 'string') this.value = +value / 10**precision;
            else this.value = value;
            this.precision = precision;
            this.symbol = symbol;
        } else {
            throw new Error('Invalid constructor arguments');
        }
    }

    toString(): string {
        return `${this.value} ${this.symbol}`;
    }
}