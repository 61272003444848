import { Injectable } from "@angular/core";
import { ApiCollection } from "./nft.service";

@Injectable()
export class DataService {
    private _collection?: ApiCollection;

    get collection() {
        return this._collection;
    }

    // set collection(val: ApiCollection) {
    //     this._collection = val;
    // }
    
    constructor() {
        // console.log("Init data service");
    }

    setCollection(collection: ApiCollection) {
        this._collection = collection;
        // console.log("Collection in Data Service set.", this.collection);
    }
}