import { Component, OnInit } from '@angular/core';
// import { LoadingController, AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { SystemService } from '../../_services/system.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService, User } from '../../_services/auth.service';
import { MANDOX_API, WIRE_API } from '../../_constants/constants';
import { CryptoService, Key } from '../../_services/crypto.service';
import { HttpClient } from '@angular/common/http';
import { ConnectService, MetamaskError } from "../../_services/connect.service";
import { ethers } from "ethers";
// import md5 from 'md5';
const md5 = require('md5');

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegisterComponent } from '../register/register.component';
import { ResetComponent } from '../reset/reset.component';
import { AlertController } from 'src/app/_services/alert.service';
import { ContractService } from 'src/app/_services/contract.service';
import { LoadingController } from 'src/app/_services/load.service';


const usernameRegex = new RegExp("[^a-z1-5]+")

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    username?: string
    passwordType: string = 'password'
    passwordIcon: string = 'eye-off-outline'
    password? : string

    custom_passphrase? : boolean
    metamask_login_form!: FormGroup;
    standard_login_form!: FormGroup;
    KEY?: Key

    connectedAddress? : string

    tabIndex : number = 0

    constructor(
        private contract : ContractService,
        public connect: ConnectService,
        private alert: AlertController,
        private crypto : CryptoService,
        public system: SystemService,
        private auth: AuthService,
        private load: LoadingController,
        public dialogRef: MatDialogRef<LoginComponent>,
        private dialog: MatDialog,
        private http: HttpClient) {
    }

    ngOnInit() {
        // let ethereum = (window as any).ethereum;
        // if (typeof ethereum == 'undefined') this.tabIndex = 1 
        // else this.connect.connect()

        this.metamask_login_form = new FormGroup({
            username: new FormControl('', (x: any) => { return null }),
            passphrase: new FormControl('', (x: any) => { return null }),
            custom_passphrase: new FormControl(false),
        });
        this.standard_login_form = new FormGroup({
            username: new FormControl('', (x: any) => { return null }),
            password: new FormControl('', (x: any) => { return null }),
        });

        this.auth.on('address-set').subscribe((res : any)=>{
            this.connectedAddress = res
            this.http.get(`https://api.airwire.io/ethUsers/${res}`).toPromise().then((result: any) => {
                this.username = result.user;
                this.metamask_login_form.setValue({'username': result.user.toLowerCase(), 'passphrase': '', 'custom_passphrase': false});
            }).catch((err) => {
                this.system.showToast({ header: "No Account Linked to this Address. Please register", color: 'danger', duration: 6000 });
            })
            
        })
        this.auth.on('remove-address').subscribe((res : any)=>{
            this.connectedAddress = undefined
        })
        
        if (this.connect.connected) {
            this.connectedAddress = this.connect.accounts[0]
            // console.log("Getting Account");
        }
    }

    // STANDARD 
    async login(){
        let username = this.standard_login_form.value.username.toLowerCase()
        let password = this.standard_login_form.value.password
        
        if (!username || !password){
            this.connect.showToast({ header: 'Username & Password required!', color: 'warning'})
            return
        }
        const loading = await this.load.create({ spinner: 'crescent', message: `Logging in...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();

        this.crypto.generateKeySeed(username, password).then((key : Key) => { 
            // console.log("Key: ", key);           
            this.http.post(MANDOX_API + "/login", { username, key: key.pub_key }).subscribe((res : any) => {
                // console.log("Here:", res);
                loading.dismiss()
                let user : User = res
                user.profilePic = res.profilePic
                user.bannerPic = res.bannerPic

                this.auth.login(user)
                this.auth.setKey(key)
                this.contract.login(key)

                this.dialogRef.close()
                setTimeout(() => {
                    this.auth.emit('login', user.username)
                    this.connect.showToast({ header: `Login Success`, message: `Welcome, ${username}!`, color: 'success' })
                }, 500)

            }, (error:any) => {
                console.log(error.error);
                loading.dismiss()
                this.system.showToast({ header: `Something went wrong...`, message: error.error, color: 'danger' })
            })
        })
    }

    async connectMetamask() {
        if (!(window as any).ethereum) {
            // Not Metamask supported browser
            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Open Metamask',
                message: `This browser doesn't support Metamask. Click to continue with Metamask browser`,
                buttons: [{
                    text: 'Continue',
                    handler: () => {
                        let url = 'https://metamask.app.link/dapp/app.dragonspawn.com'
                        if (this.auth.user) url = `https://metamask.app.link/dapp/app.dragonspawn.com`
                        window.open(url, '_blank')
                    }
                }, {
                    text: 'Cancel',
                    role: 'cancel'
                }]
            });
            await alert.present();
        }
        else {
            this.connect.connect()
        }
    }

    async generateLocalKey() {
        if (!this.metamask_login_form.value.username) {
            this.connect.showToast({ header: `Username required`, color: 'danger' })
            return
        }

        const loading = await this.load.create({ spinner: 'crescent', message: `Generating Keys...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();

        let username = this.metamask_login_form.value.username.toLowerCase();
        
        let passphrase: string = this.custom_passphrase && this.metamask_login_form.value.passphrase 
            ? 
            this.metamask_login_form.value.passphrase 
            : 
            this.metamask_login_form.value.username;

        // let passHash: string = md5(passphrase);
        // console.log(username, passphrase);

        try {
            let ethereum = (window as any).ethereum;
            let provider = new ethers.providers.Web3Provider(ethereum);
            let signer = provider.getSigner();
            let signature = await signer.signMessage(md5(passphrase));
            
            // console.log("check 1", this.crypto.generateKeyMaBoi());
            // console.log("check 2", this.crypto.generateKeyMaBoi());
            // Checked here
            this.KEY = await this.crypto.generateKeySeed(username, signature); 
            // console.log("Key: ", this.KEY);
            
            // setTimeout(() => {  console.log("hey hey hey", window.performance.now()); }, 1);
            this.loginMetamask(username, this.connectedAddress ? this.connectedAddress : this.connect.accounts[0])
            loading.dismiss()
        }
        catch (err : any | MetamaskError) {
            loading.dismiss()
            this.connect.showToast({ header: "MetaMask Error", message : err.message.replace('MetaMask Message Signature: ', ''), color: 'danger' });
            console.log(err.message ? err.message : err);
        }
    }

    async loginMetamask(username : string, address : string){
        const loading = await this.load.create({ spinner: 'crescent', message: `Logging in...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();

        this.contract.checkEthAccount(username, address).then((res:any) =>{
            if (res){
                this.http.post(WIRE_API + "/login", { username: this.metamask_login_form.value.username.toLowerCase(), key: this.KEY!.pub_key }).subscribe(async (res : any) => {
                    console.log("MetaMask Login Response:",res);
                    loading.dismiss()
                    let user : User = res.data

                    // THIS IS GETTING USER PROFILE DATA FROM MANDOX.USERS CONTRACT
                    await this.contract.getUser(user.username).then((res) => {
                        console.log("META MASK SIGN IN MANDOX.USERS RESPONSE: ", res);
                        user = res;
                    }).catch((err) => {
                        console.log("Error retrieving user from mandox.users:", err);
                    })

                    // user.profilePic = res.profilePic
                    // user.bannerPic = res.bannerPic

                    this.auth.login(user)
                    // this.discord.login(user.username)
                    this.auth.setKey(this.KEY!)
                    this.contract.login(this.KEY!)
                    this.dialogRef.close();
    
                    setTimeout(() => {
                        this.auth.emit('login', user.username)
                        this.connect.showToast({ header: `Login Success`, message: `Welcome, ${this.metamask_login_form.value.username}!`, color: 'success' })
                    }, 500)
                }, (error:any) => {
                    console.log(error.error);
                    loading.dismiss()
                    this.connect.showToast({ header: `Login Error`, message: error.error, color: 'danger' })
                })
            }
        }, (err:any) =>{
            loading.dismiss()
            this.connect.showToast({ header: `Login Error`, message: err, color: 'danger' })
            console.log(err);
        })
    }

    getErrorMessageFromRPC(e: any): any {
        let message = "Error occurred in WIRE network.";
        let substring = "[ethjs-query] while formatting outputs from RPC";
        if (e.message && e.message.indexOf(substring) > -1) {
            let parts = e.message.split("'");
            if (parts.length > 0) {
                let error = JSON.parse(parts[1]);
                if (error && error.value && error.value.data) {
                    message = error.value.data;
                }
                if (message && message.length > 1) message = message[0].toUpperCase() + message.slice(1);
            }
        }
        message = message.replace('assertion failure with message:', '')
        return message;
    }

    async accountOptions() {
        const alert = await this.alert.create({
            header: 'MetaMask Options',
            message: `Link a different account via MetaMask, or Disconnect from MetaMask.
                <br><br>Linked Address:<br><span class="courier light-blue">${this.connectedAddress}</span>`,
            buttons: [
                {
                    text: 'Link Different',
                    handler: () => { this.connect.editAccounts() }
                }, 
                {
                    text: 'Disconnect',
                    handler: () => { this.connect.disconnect() }
                }
            ]
        });
        await alert.present();
    }

    togglePassword() {
        this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
        this.passwordIcon = this.passwordIcon === 'eye-off-outline' ? 'eye-outline' : 'eye-off-outline';
    }

    async openRegister(){
        this.dialogRef.close();
        this.dialog.open(RegisterComponent, {
            panelClass: 'secondary-modal'
        });
    }

    openReset() {
        this.dialogRef.close();
        this.dialog.open(ResetComponent, {
            panelClass: 'main-modal'
        });
    }
}
